@import '~bootstrap/scss/bootstrap';

h1 {
  margin: 0;
  padding: 0;
}

h2 {
  margin-bottom: 0;
  padding: 0;
  font-size: calc(10px + 2vmin);
  font-weight: 200;
}

/* has to overwrite z-index for confetti */
canvas {
  z-index: 1301 !important;
}

/* important needed to override inline beacon styles */
.Beacon {
  z-index: 1200;
  position: relative;
  
  .BeaconFabButtonFrame {
    bottom: 55px !important;
    @media (min-width: 768px) {
      bottom: 30px !important;
    }
    right: 20px !important;
  }
}
